import React, { useEffect, useRef, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import axios from "axios";
import Recaptcha from "react-recaptcha";
import { useSelector } from "react-redux";

import { Button, MapBox } from "../components";
import { BACKEND_BASE_URL } from "../constant/constants";

const Contact = ({ locations }) => {
  const ref = useRef(null);
  const [map, setMap] = useState();

  const initialFormValues = {
    name: "",
    email: "",
    subject: "",
    message: "",
    phone: "",
  };

  const userValidationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field")
      .min(2, "Atleast 2 alphabets required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    subject: Yup.string()
      .required("Subject is required")
      .min(2, "Atleast 2 alphabets required"),
    message: Yup.string()
      .required("Message is required")
      .min(2, "Atleast 2 alphabets required"),
    phone: Yup.string()
      .required("Phone number is required")
      .max(10, "Atleast 10 Number is required")
      .matches(/^[6-9][0-9]{9}/, "Atleast 10 Number is required"),
  });

  let recaptchaInstance;

  const handleFormSubmit = (values, reset, token) => {
    axios
      .post(BACKEND_BASE_URL + "contact/submit", {
        name: values.name,
        email: values.email,
        subject: values.subject,
        message: values.message,
        phone: values.phone,
        "g-recaptcha-response": token,
      })
      .then(res => {
        if (res) {
          Swal.fire({
            title: "Thank you for getting in touch!",
            text: "We appreciate you contacting us. We will get back to you soon.",
            icon: "success",
            confirmButtonText: "OK",
          }).then(result => {
            reset();
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map]);

  const { configData } = useSelector(state => state.configReducer);

  const formRef = useRef();

  const verifyCallback = response => {
    handleFormSubmit(
      formRef.current.values,
      formRef.current.resetForm,
      response
    );
  };

  return (
    <div className="contact">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2 className="about-title mt-4">Contact US</h2>
          </div>
        </div>
        <div className="row">
          {locations.map((location, index) => {
            return (
              <div className="col-12 col-sm-6" key={index + location.email}>
                <div className="address-card mb-3">
                  <div className="card-header p-0">
                    <img
                      src={location.image}
                      className="card-img-top address-card__image"
                      alt={location.name}
                    />
                  </div>
                  <div className="card-body">
                    <h5 className="card-title address-card__title">
                      {location.name}
                    </h5>
                    <div className="row">
                      <address className=" col col-lg-6 address-card__address">
                        <p>{location.address}</p>
                      </address>
                      <address className=" col col-lg-6 address-card__address">
                        <a
                          href={`mailto:${location.email}`}
                          className="address-card__email"
                        >
                          <span className="address-card__icon icon ic-email"></span>
                          {location.email}
                        </a>
                        <p>
                          <a
                            href={`tel:${location.phone}`}
                            className="address-card__email"
                          >
                            <span className="address-card__icon icon ic-mobile"></span>
                            {location.phone}
                          </a>
                        </p>
                      </address>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="contact__form">
          <Formik
            enableReinitialize
            initialValues={initialFormValues}
            onSubmit={() => {
              recaptchaInstance.execute();
            }}
            validationSchema={userValidationSchema}
            innerRef={formRef}
          >
            {({ values, handleChange, handleBlur, errors, touched }) => (
              <Form className="contact__form-wrap">
                <div className="contact__form-field">
                  <h1 className="form-title">Do you have any questions?</h1>
                </div>
                <div className="contact__form-field">
                  <label htmlFor="name" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Name"
                  />
                  {touched.name && errors.name && (
                    <div className="form-text text-danger">{errors.name}</div>
                  )}
                </div>
                <div className="contact__form-field">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Email"
                  />
                  {touched.email && errors.email && (
                    <div className="form-text text-danger">{errors.email}</div>
                  )}
                </div>
                <div className="contact__form-field">
                  <label htmlFor="subject" className="form-label">
                    Subject
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="subject"
                    name="subject"
                    value={values.subject}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Subject"
                  />
                  {touched.subject && errors.subject && (
                    <div className="form-text text-danger">
                      {errors.subject}
                    </div>
                  )}
                </div>
                <div className="contact__form-field">
                  <label htmlFor="phone" className="form-label">
                    Phone
                  </label>
                  <input
                    type="Number"
                    className="form-control"
                    id="phone"
                    name="phone"
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="10 digits phone number"
                  />
                  {touched.phone && errors.phone && (
                    <div className="form-text text-danger">{errors.phone}</div>
                  )}
                </div>
                <div className="contact__form-field">
                  <label htmlFor="message" className="form-label">
                    Message
                  </label>
                  <textarea
                    className="form-control"
                    id="message"
                    name="message"
                    rows="3"
                    value={values.message}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Message"
                  ></textarea>
                  {touched.message && errors.message && (
                    <div className="form-text text-danger">
                      {errors.message}
                    </div>
                  )}
                </div>
                {configData?.recaptcha_key && (
                  <div className="contact__form-field">
                    <Recaptcha
                      ref={e => (recaptchaInstance = e)}
                      sitekey={configData?.recaptcha_key}
                      size="invisible"
                      verifyCallback={verifyCallback}
                    />
                    {touched.recaptcha && errors.recaptcha && (
                      <div className="form-text text-danger">
                        {errors.recaptcha}
                      </div>
                    )}
                  </div>
                )}
                <div className="contact__form-field d-flex justify-content-center">
                  <Button
                    type="submit"
                    title="Submit"
                    variant="solid"
                    theme="primary"
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div className="location">
        <div className="container">
          <h2 className=" about-title location__title">Location on Map</h2>
        </div>
        <MapBox locations={locations} />
      </div>
    </div>
  );
};

export default Contact;
