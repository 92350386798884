import React, { useState } from "react";

import Layout from "../components/layout";
import { Seo } from "../components";
import Contact from "../container/contact";

import chruchImg1 from "../images/about/bishop-house.jpg";
import chruchImg2 from "../images/about/diocesan-house.jpg";

const ContactPage = ({ location }) => {
  // eslint-disable-next-line no-unused-vars
  const [locations, setLocations] = useState([
    {
      image: chruchImg1,
      name: "CSI Bishop's House",
      address:
        "CSI Bishop’s house, Madhya Kerala Diocese, Cathedral Road, Kottayam, Kerala, India - 686 001.",
      email: "csimkdbishopsoffice@gmail.com",
      phone: ["0481 2566536"],
      lat: 9.596375787195813,
      long: 76.51966248279504,
    },
    {
      image: chruchImg2,
      name: "Diocesan Office",
      address:
        "CSI Diocesan Office, Madhya Kerala Diocese, Cathedral Road, Kottayam, Kerala, India - 686 001.",
      email: "csimkdkottayam@yahoo.co.in",
      phone: ["0481-2567274"],
      lat: 9.595303836697175,
      long: 76.51966248279504,
    },
  ]);

  return (
    <Layout location={location}>
      <Seo title="Contact us" />
      <Contact locations={locations} />
    </Layout>
  );
};

export default ContactPage;
